import { Machine, assign } from "xstate";

const isNegativeNumber = (context, event) => 
  (context.display === "0." || context.operator1 !== null)
  && event.operator === "-";

const round = value => Math.round(value * 1000000) / 1000000;

function doMath(operand1, operand2, operator) {
  if (operator === "+") {
    return +operand1 + +operand2;
  } else if (operator === "-") {
    return +operand1 - +operand2;
  } else if (operator === "*") {
    return +operand1 * +operand2;
  } else if (operator === "/") {
    return + operand1 / operand2;
  } else {
    return Infinity;
  }
}

export const calculatorMachine = Machine({
    id: 'calculator',
    initial: 'clear',
    context: {
      display: '0.',
      operand1: null,
      operand2: null,
      operator1: null,
      operator2: null
    },
    states: {
      clear: {
        entry: "resetContext",
        on: {
          NUMERAL: {
            target: "operand1.beforeDecimal",
            actions: "insertBeforeDecimal"
          },
          DECIMAL: "operand1.afterDecimal",
          OPERATOR: {
            cond: isNegativeNumber,
            target: "operand1.beforeDecimal",
            actions: "setNegativeDisplay"
          }
        }
      },
      
      operand1: {
        exit: "setOperand1",
        on: {
          CLEAR_ALL: "clear",
          OPERATOR: "operator"
        },
        states: {
          beforeDecimal: {
            on: {
              NUMERAL: {
                actions: "insertBeforeDecimal"
              },
              DECIMAL: "afterDecimal"
            }
          },
          afterDecimal: {
            on: {
              NUMERAL: {
                actions: "appendAfterDecimal"
              }
            }
          }
        }
      },

      operator: {
        entry: "setOperator1",
        on: {
          NUMERAL: {
            target: "operand2.beforeDecimal",
            actions: [
              "setPositiveDisplay",
              "insertBeforeDecimal"
            ]
          },
          DECIMAL: {
            target: "operand2.afterDecimal",
            actions: "setPositiveDisplay"
          },
          OPERATOR: {
            cond: isNegativeNumber,
            target: "operand2.beforeDecimal",
            actions: "setNegativeDisplay"
          },

          CLEAR_ALL: "clear"
        }
      },

      operand2: {
        exit: "setOperand2",
        on: {
          CLEAR_ALL: "clear",
          OPERATOR: {
            target: "result.byOperator",
            actions: "setOperator2"
          },
          EQUALS: "result.byEquals",
        },
        states: {
          beforeDecimal: {
            on: {
              NUMERAL: {
                actions: "insertBeforeDecimal"
              },
              DECIMAL: "afterDecimal"
            }
          },
          afterDecimal: {
            on: {
              NUMERAL: {
                actions: "appendAfterDecimal"
              }
            }
          }
        }
      },

      result: {
        entry: "evaluate",
        on: {
          CLEAR_ALL: "clear"
        },
        states: {
          byEquals: {
            on: {
              NUMERAL: {
                target: "#calculator.operand1.beforeDecimal",
                actions: [
                  "resetContext",
                  "insertBeforeDecimal",
                ]
              },
              DECIMAL: {
                target: "#calculator.operand1.afterDecimal",
                actions: [
                  "resetContext",
                  "insertAfterDecimal",
                ]
              },
              OPERATOR: {
                cond: isNegativeNumber,
                target: "#calculator.operand1.beforeDecimal",
                actions: [
                  "resetContext",
                  "setNegativeDisplay"
                ]
              }
            }
          },
          byOperator: {
            on:{
              NUMERAL: {
                target: "#calculator.operand2.beforeDecimal",
                actions: [
                  "setOperand1",
                  "setOperator2ToOperator1",
                  "setPositiveDisplay",
                  "insertBeforeDecimal"
                ]
              }
            }
          }
        }
      }
    }
  },
	{
		guards: { isNegativeNumber },

		actions: {
      resetContext: assign({
        display: () => "0.",
        operand1: () => null,
        operand2: () => null,
        operator1: () => null,
        operator2: () => null
      }),

      setPositiveDisplay: assign({ display: () => "0." }),
      
      setNegativeDisplay: assign({ display: () => "-0." }),

      setOperand1: assign({ operand1: (context) => Number(context.display) }),

      setOperand2: assign({ operand2: (context) => Number(context.display) }),

      setOperator1: assign({ operator1: (_, event) => event.operator }),

      setOperator2: assign({ operator2: (_, event) => event.operator }),

      setOperator2ToOperator1: assign({
        operator1: (context) => context.operator2,
        operator2: () => null
      }),

      evaluate: assign({
        display: ({operand1, operand2, operator1}) => {
          const result = doMath(operand1, operand2, operator1);
          console.log(`Calculating: ${operand1} ${operator1} ${operand2} = ${result}`);
          if (Number.isInteger(result)) {
            return result + ".";
          }
          return String(round(result));
          //return String(result);
        }
      }),

      insertBeforeDecimal: assign({ display: (context, event) => {
        if (context.display === "0.") {
          return event.key + ".";
        } else if (context.display === "-0.") {
          return "-" + event.key + ".";
        } else {
          return context.display.slice(0,-1) + event.key + ".";
        }
      } }),

      appendAfterDecimal: assign({ display: (context, event) => context.display + event.key }),
    }
	});
