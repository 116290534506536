/** @jsx jsx */
import { jsx } from "theme-ui";
import { useMachine } from "@xstate/react"

import './calculator.css';
import Display from "./display";
import Button from "./button"
import { calculatorMachine } from "./calculator-machine";

export default () => {
	const [state, send] = useMachine(calculatorMachine);

	function sendMachine(event, payload) {
		console.log(event, payload);
		send(event, payload);
		console.log(state.value, state.context)
	} 

  function isOperator(value) {
    if ("+-*/".includes(value)) return true;
    return false;
	}
	
function handleClick(value) {
    if (Number.isInteger(value)) {
      sendMachine("NUMERAL", { key: +Number(value) });
    } else if (isOperator(value)) {
      sendMachine("OPERATOR", { operator: value });
    } else if (value === ".") {
      sendMachine("DECIMAL");
    } else if (value === "Clear") {
      sendMachine("CLEAR_ALL");
    } else {
      sendMachine("EQUALS");
    }
  }
	
	const calculatorButtons = [
    "Clear",
    "+",
    7,
    8,
    9,
    "-",
    4,
    5,
    6,
    "*",
    1,
    2,
    3,
    "/",
    0,
    ".",
    "="
	];
	
	return (
		<div
			sx={{
				border: `black solid 2px`,
				display: `flex`,
				flexWrap: `wrap`,
				m: `auto`,
				mt: `50px`,
				width: `204px`
			}}
		>
			<Display displayText={state.context.display}/>
			{calculatorButtons.map(button => (
				<Button
					key={button}
					styling={
							button === "Clear" ? "btn-lg" : button === 0 ? "btn-md" : "btn-sm"
					}
					text={button}
					onClick={() => handleClick(button)}
				/>
			))}
		</div>
	)
}