/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Calculator from "../components/calculator/calculator"
import SiteLinks from "../components/site-links"
import { Separator } from "../components/gatsby-sidebar/docs-breadcrumb"

const ProjectCalculator = ({ location }) => (
  <Layout>
    <SEO title="Project - Calculator" />

    <div
      sx={{
        m: `1.45rem auto`,
        maxWidth: 900,
      }}
    >
      
      <p>This is a simple four function calculator.</p>
      <Calculator />
    </div>


    <SiteLinks>
      <Link to="/">Landing Page</Link>
      <Separator character={<span>{` `}</span>} />
      <Link to="/index-blog/">Blog</Link>
      <Separator character={<span>{` `}</span>} />
      <Link to="/index-project/">Projects</Link>
    </SiteLinks>
  </Layout>
)

export default ProjectCalculator
